const photos = [
    {
        url: "https://i.imgur.com/524ssWe.jpg",
        alt: "Orchestra Performance 1",
        fallback: "url('/assets/images/events-background.jpg')",
    },
    {
        url: "https://i.imgur.com/bADpOJD.jpg",
        alt: "Orchestra Performance 3",
        fallback: "url('/assets/images/home-background.jpg')",
    },
    {
        url: "https://i.imgur.com/hOyMGWE.jpg",
        alt: "Orchestra Performance 2",
    },
    {
        url: "https://i.imgur.com/H9f9PjG.jpg",
    },
    {
        url: "https://i.imgur.com/7H3UNTh.jpg",
    },
    {
        url: "https://i.imgur.com/rjNywgv.jpg",
    },
    {
        url: "https://i.imgur.com/CwZSJFk.jpg",
    },
    {
        url: "https://i.imgur.com/XsZu9wA.jpg",
    },
    {
        url: "https://i.imgur.com/DQLQNZW.jpg",
    },
    {
        url: "https://i.imgur.com/A76qfB0.jpg",
    },
    {
        url: "https://i.imgur.com/sbiEjAr.jpg",
    },
    {
        url: "https://i.imgur.com/HGBa0VJ.jpg",
    }
];

export default photos;